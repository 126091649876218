import React from 'react';
import { above } from 'src/components/global/mediaqueries';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';
import Tag from 'src/components/typography/Tag';

const InfoSection = styled('div')`
    display: flex;
    justify-content: space-between;

    border-top: 1px solid ${theme.color.black};
    border-bottom: 1px solid ${theme.color.black};
    color: ${theme.color.black};

    margin-top: 24px;
    padding: 12px;

    ${above.desktop_s} {
        margin-top: 40px;
        padding: 24px;
    }

    padding-left: 0;
    padding-right: 0;
`;

const TagWrapper = styled('div')`
    display: flex;
`;

const StyledTag = styled(Tag)`
    font-weight: 500;
`;

const PriceText = styled('span')`
    line-height: 1;
    font-size: 12px;
    font-weight: 400;
    margin-left: 16px;
`;

const VariationText = styled('span')`
    margin-left: 5px;
    line-height: 1;
    font-size: 12px;
`;

const CartProductVariation = ({price, variation}) => {
    return (
        <InfoSection>
            <TagWrapper>
                <StyledTag>PDF & Excel:</StyledTag>
                <VariationText>{variation}</VariationText>
            </TagWrapper>
            <TagWrapper>
                <StyledTag>Price:</StyledTag>
                <PriceText>{price}</PriceText>
            </TagWrapper>
        </InfoSection>
    );
};

CartProductVariation.propTypes = {
    price: PropTypes.string,
    variation: PropTypes.string,
};

CartProductVariation.defaultProps = {
    price: '',
    variation: '',
};

export default CartProductVariation;